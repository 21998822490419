import * as React from 'react';

import Meta from '../components/meta';
import SecondaryPageLayout from '../components/secondary-page-layout';
import { FAQPageTitle, FAQPageContent } from '../localisation';
import { Url } from '../url';

import * as classes from './faq.module.css';

function FaqPage() {
  return (
    <SecondaryPageLayout>
      <Meta
        title={FAQPageTitle}
        url={Url.FAQ}
      />

      <div className={classes.content}>
        <h1>{FAQPageTitle}</h1>

        <FAQPageContent />
      </div>
    </SecondaryPageLayout>
  );
}

export default FaqPage;
